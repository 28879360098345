@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/fonts.scss" as *;
@use "../../styles/partials/mixins.scss" as *;
.home{
&__hero {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../assets/images/bangladeshlandscape.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 80vh;
  padding: 1rem;

  @include desktop {
    padding: 2rem;
  }
}
&__title {
  color: white;
  font-family: "Montserrat";
  font-weight: 800;
  text-align: center;
  font-size: 7rem;
  padding-top: 15%;
  letter-spacing: 0.5rem;
  @media (max-width: 768px) {
    padding-top: 20%;
    font-size: 2.5rem;
  }
  opacity: 0; /* Ensure they start as invisible */
  animation: resfadein 0.5s ease-in forwards; /* Apply the fade-in animation */
}

&__subtitle {
  color: white;
  font-family: "Montserrat";
  font-weight: 300;
  text-align: center;
  font-size: 2rem;
  padding-top: 4%;
  letter-spacing: 0.5rem;
  @media (max-width: 768px) {
    padding-top: 30%;
    font-size: 1.5rem;
  }
  opacity: 0; /* Ensure they start as invisible */
  animation: resfadein 1s ease-in forwards; /* Apply the fade-in animation */

}
&__introduction{
  font-family: "Montserrat";
  padding: 2rem 2rem;
  font-size: 1.15rem;
  font-weight: 200;
  @include desktop{
    font-size: 1.5rem;
    padding: 2rem 8rem; 
  }
}
&__container {
  @include desktop {
    margin: 1vw 10vw 4rem;
  }
}
&__main{
  color:white;
  &-title{
    font-family: "Montserrat";
    font-weight: 300;
    color:white;
    font-size: 4rem;
    padding: 7rem 0rem 3rem;
    text-align: center;
    @include mobile {
      letter-spacing: 0.3rem;
      padding: 3rem 0rem 3rem;
      font-size: 2rem;

    }
  }
}
&__span{
  padding: 0%;
  font-size: 3rem;
  letter-spacing: 1.5rem;
  @media (max-width: 768px) {
    padding-top: 20%;
    font-size: 2rem;
  }
}
}