// Imports
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.button {
  background-color: rgb(243, 207, 115);
  padding: 7rem 0rem;

  // For mobile devices, adjust padding
  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }

  // Title styles
  &_title {
    margin: 2rem 3rem;
    font-size: 3rem;
    text-align: center;
    color: black;

    // For mobile devices, adjust font-size and margin
    @media (max-width: 768px) {
      font-size: 1.25rem;
      margin: 0 0 2rem;

    }
  }

  &_span {
    color: white;
  }

  // Link styles
  &_link {
    display: inline-flex;
    justify-content: flex-end;
    align-content: flex-end;
    text-decoration: none;
    font-size: 0.7rem;
    padding: 1rem 3rem;

    &:hover {
      transition-delay: 0.2s;
      color: rgba(223, 190, 106);
      text-decoration: none;
    }

    &.active {
      color: rgb(243, 207, 115);
      text-decoration: none;
    }
// For mobile devices, adjust padding and font-size
@media (max-width: 768px) {
  font-size: 0.6rem;
  padding: 0.5rem 1.5rem;
}
}

// Button styles
.button_button, .button_btn-container {
  white-space: nowrap;
  background: transparent;
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.7rem;
  color: black;
  border-radius: 0;
  background: linear-gradient(
    270deg,
    rgba(223, 190, 106, 0.8),
    rgba(147, 111, 52, 0.8),
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0)
  );
  background-position: 1% 50%;
  background-size: 300% 300%;
  text-decoration: none;
  border: 1px solid black;
  transition: all 0.7s ease-in-out;
  @media (max-width: 768px) {
    font-size: 0.5rem;
    letter-spacing: 0.5rem;
  }

  // Hover effect for button
  &:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0.3);
    background-position: 99% 50%;
    background-color: black;
  }
}
}

// Container for buttons
.button_bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    column-gap: 1.5rem;
    flex-direction: column;
    row-gap: 1rem;
  }
}
