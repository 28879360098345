@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;



.dots {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.dot {
  height: 12px;
  width: 10px;
  background-color: white; // Flat color for inactive dots
  margin: 0 5px;
  transition: background-color 0.6s ease;
  border-radius: 50%;
  // Remove any border and outline effects
  border: none;
  outline: none;

  // Ensure the dots are flat without any shadow or 3D effects
  box-shadow: none;
}

.dot.active {
  background-color: #ffdd84; // Flat color for active dots
  // Repeat the removal of border and outline for active state, if necessary
  border: none;
  outline: none;
  box-shadow: none;
}


.carousel {
  margin: 2rem 0rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

  &__arrow {
    color: #ffdd84;
    cursor: pointer;
    z-index: 1;
  }
  &__top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__arrow--prev {
    margin-right: 10px; // Adjust distance from left edge
    color: #ffdd84;
    background-color: black;
    cursor: pointer;
  }

  &__arrow--next {
    margin-left: 10px; // Adjust distance from right edge
    color: #ffdd84;
    background-color: black;
    cursor: pointer;
  }

	&-image-container {
		display: flex;
		justify-content: center;
    position: relative;
	}

	&__image {
    width: 85vw; // Adjust based on your preference
		height: 200px; // Adjust based on your preference
		object-fit: cover;
		border-radius: 15px;
		@include tablet {
      width: 40vw; // Adjust based on your preference
			height: 250px; // Adjust based on your preference
		}
	}
}
