@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.services {
	padding: 1rem;
	background-color: black;
	@include tablet {
		padding: 2rem;
	}
	@include desktop{
		padding: 4rem;
	}

	&__title {
		font-family: "Montserrat";
		font-weight: 300;
		font-size: 3rem;
		margin-bottom: 30px;
		color: rgb(243, 207, 115);
		text-align: center;
	}
}

.service {
	font-family: "Montserrat";
	font-weight: 300;
	display: flex;
	align-items: start; // Align items at the start for desktop and tablet views
	margin-bottom: 20px;
	padding: 15px;
	background-color: black;
	border-radius: 8px;
	@include mobile {
		flex-direction: column;
		align-items: center; // Center align items for mobile view
	}
	@include tablet{
		padding: 1rem 5rem;

	}

	&--reverse {
		@include tablet {
			flex-direction: row-reverse;
			.service__content {
				text-align: right; // Align text to the right for reverse layout on desktop and tablet
			}
		}
		@include mobile {
			flex-direction: column;
			align-items: center; // Reset alignment for mobile view
			&__content {
				text-align: center; // Center align text for mobile view
			}
		}
	}

	&__image img {
		width: 300px; // Adjust based on your preference
		height: 150px; // Adjust based on your preference
		object-fit: cover;
		border-radius: 8px;
		@include desktop {
			width: 400px; // Adjust based on your preference
			height: 200px; // Adjust based on your preference
		}
	}

	&__content {
		flex: 1;
		@include tablet {
			padding: 0 20px;
		}
		color: white;
		&__name, &__description {
			@include tablet {
				text-align: left; // Align text to the left for normal layout on desktop and tablet
			}
			@include mobile {
				text-align: center; // Center align text for mobile view
			}
		}
	}
	&__name {
    @include mobile{
      text-align: center;
    }

		color: white;
		margin-bottom: 10px;
		margin-top: 1rem;
		font-size: 1.5rem;
		font-weight: 300;
		@include desktop {
			font-size: 2rem;
		}
	}

	&__description {
    @include mobile{
      text-align: center;
    }
		color: white;
		margin-bottom: 10px;
		font-size: 1rem;
		font-weight: 300;
		@include desktop {
			font-size: 1.5rem;
		}
	}
}

.service--normal,.service--reverse {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.service--reverse.is-inView, .service--normal.is-inView{
  opacity: 1;
  transform: translateY(0px);
}