/* MobileNavbar.module.css */

.toolbar {
  background-color: black;
  justify-content: space-between;
  height: 80px;
}

.title {
  /* display: flex;
  justify-content: flex-start; */
  text-align: center;
  letter-spacing: 0.3rem;
  margin-left: 1rem;
  font-family: "Acumin Pro", serif;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  color: #dfbe6a;
}

.closeButton {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 1000; /* Ensures it's on top of other elements */
}

.drawerContent {
  text-align: center;
  display: flex;
}

.column {
  text-align: center;
  flex: 1;
  padding: 0.25rem;
}

.columnHeader {
  text-align: center;
  color: rgba(223, 190, 106);
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  padding: 0.5rem;
  padding-bottom: 2rem;
}

.drawerFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #ddd;
  padding: 1rem;
  font-size: 1.25rem;
}

.contactLink {
  color: rgba(223, 190, 106);
  display: block; /* or inline-block, depending on layout */
  margin-bottom: 1rem; /* Adjust as needed */
}

a {
  color: inherit;
  padding: 0%;
}

.top {
  padding: 0.5rem 1rem 0rem 0rem;
  display: flex;
  justify-content: end;
}

/* MobileNavbar.module.css */
.listItemText {
  text-align: center; /* Center align text */
  font-size: 1.25rem; /* Bigger font size, adjust as needed */
  width: 100%; /* Ensure the text takes the full width to center properly */
}

.listItem {
  justify-content: center; /* Center the ListItem if needed */
}

.logo{
  width: 80%;
  justify-content: center;
  align-items: center;
}