// Assuming these imports are correctly pathed and contain necessary mixins and variables
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/fonts.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

@keyframes resfadein {
  to {
    opacity: 1;
  }
}

.contact {
  font-family: "Montserrat";
  font-weight: 300;
  position: relative;
  background: url("../../assets/images/pics.jpg") no-repeat center / cover;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  &__heading {
    font-size: 3rem;
    padding: 2rem 0rem;
    font-family: "Montserrat";
    font-weight: 300;
    position: relative;
    z-index: 2;
    color: #fff;
    opacity: 0;
    animation: resfadeinContactHeading 0.5s ease-in forwards;
    @include desktop{
      font-size: 4rem;
    }
    @keyframes resfadeinContactHeading {
      to {
        opacity: 1;
      }
    }
  }
}

.contact-us {
  font-family: "Montserrat";
  font-weight: 300;
  display: flex;
  column-gap: 3rem;
  justify-content: center;
  flex-direction: column;
  color: white;
  position: relative;
  z-index: 2;
  animation: resfadein 1s ease-in forwards 0.5s;
  padding-right: 3rem;
  @include desktop{
    padding-right: 0rem;
    flex-direction: row;
  }

  &__heading, &__address, &__hour, &__day {
    font-weight: 300;
  }
  
  &__hrs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 300;
  }
  
  &__box {
    z-index: 5;
    @media (max-width: 768px) {
      padding: 1rem 0rem;
    }
    opacity: 0; // Ensure the element is initially invisible to see the fade-in effect.
    animation: resfadeinContactBox 1s ease-in forwards 1s;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
    }
    @keyframes resfadeinContactBox {
      to {
        opacity: 1;
      }
    }
  }
}
