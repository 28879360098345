@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.footer {
  display: none;
  flex-direction: column;
  padding: 2rem 4rem;
  background-color: black;
  color: #fff;
  column-gap: 5rem;
  @include desktop {
    display: flex;
  }
  &_top {
    display: flex;
    justify-content: space-between;
  }

  &_socials {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 4rem;
  }

  &_column {
    display: flex;
    flex-direction: column;
    width: 25%;
    p {
      margin: 0%;
      padding: 0%;
    }

    a {
      color: #fff;
      margin-bottom: 5px;
      text-decoration: none;
      &:hover {
        transition-delay: 0.5s;
        color: rgb(223, 190, 106);
      }
    }
    &-one,
    &-two {
      display: flex;
      flex-direction: column;
    }
  }
  &_header {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    color: rgb(223, 190, 106);
    margin: 2rem 0rem;
  }

  &_columns {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }

  &_open {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &_link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-decoration: none;
    font-size: 0.7rem;
  }
  &_logo {
    font-family: "Acumin Pro";
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
    color: rgb(223, 190, 106);
    margin: 2rem 0rem;
    letter-spacing: 0.2rem;
  }

  &_bottom {
    h4 {
      font-family: Helvetica, sans-serif;
      font-size: 0.9rem;
      text-align: center;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      color: white;
      margin-top: 4rem;
      span {
        color: rgb(243, 207, 115);
      }
    }
  }
}

.hours {
  &_item {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
  }
}
