@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.menu {
	color: white;
	font-family: "Acumin Pro";
	font-style: normal;
	font-weight: normal;

	&__hero {
		background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.2),
				rgba(0, 0, 0, 0.5)
			),
			url("../../assets/images/bengalisun.jpg");
		background-size: cover;
		background-position: top;
		background-repeat: no-repeat;
		background-attachment: fixed;
		height: 60vh;
		padding: 1rem;
		@include tablet {
			height: 50vh;
		}
		@include desktop {
			padding: 2rem;
			height: 100vh;
		}
	}
	&__title {
		font-family: "Montserrat";
		font-weight: 300;
		text-align: center;
		font-size: 7rem;
		padding-top: 15%;
		letter-spacing: 0.5rem;
		@media (max-width: 768px) {
			padding-top: 20%;
			font-size: 4rem;
		}
		opacity: 0; /* Ensure they start as invisible */
		animation: resfadein 0.5s ease-in forwards; /* Apply the fade-in animation */
	}

	&__subtitle {
		font-family: "Montserrat";
		font-weight: 300;
		text-align: center;
		font-size: 2rem;
		padding-top: 4%;
		letter-spacing: 0.5rem;
		@media (max-width: 768px) {
			padding-top: 30%;
			font-size: 2rem;
			letter-spacing: 0.3rem;
		}
		opacity: 0; /* Ensure they start as invisible */
		animation: resfadein 1s ease-in forwards; /* Apply the fade-in animation */
	}
	&__container {
		@include desktop {
			margin: 1vw 10vw 4rem;
		}
	}

	&__category {
		border-bottom: 1px solid white;
		padding: 3rem 0rem 4rem;
		@include tablet {
			padding: 3rem;
		}
		@media (max-width: 768px) {
			padding: 3rem 2rem 4rem;

		}

		&:last-child {
			border-bottom: none;
		}
		&-header {
			text-align: center;
			font-size: 2rem;
			padding: 3rem;
			letter-spacing: 0.5rem;
		}
	}
	&__feature {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
	
		// Add a media query for mobile devices
		@media (max-width: 768px) {
			margin: 0 auto;
			grid-template-columns: repeat(1, 1fr);
			width: 80%;
		}
		@include tablet{
			padding: 2rem 5rem;
		}
		@include desktop{
			padding: 2rem 0rem;
		}
	}

	&__item {
		color: rgb(189, 189, 189);
		font-family: "Montserrat";
		font-weight: 300;
		margin: 3rem;
		text-align: center;
		font-size: 2rem;
		letter-spacing: 0.5rem;

		@media (max-width: 768px) {
			font-size: 1.5rem;
			margin: 0.5rem;
		}
	}
	&__lists {
		column-count: 2;
		column-gap: 2rem;
		width: 100%;

		@media (max-width: 768px) {
			column-count: 1;
		}
	}

	&__list {
		break-inside: avoid;
		margin-bottom: 2rem;
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
		@media (max-width: 768px) {
			text-align: center;
			padding-bottom: 1rem;
		}
		&-top {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}
		&-spice{
			margin-left:0.5rem;
		}
		&--content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 90%;
		}
		&-item {
			justify-content: center;
			padding: 0;
			margin: 0;
			font-size: 1.5rem;
			@media (max-width: 768px) {
				text-align: left;
				font-size: 1.25rem;
			}
		}
		&-price {
			margin-left: 1rem;
			font-size: 1.5rem;
		}
		&-description {
			color: #ff4646;
			width: 80%;
			padding: 0%;
			margin: 0%;
			font-family: "Acumin Pro Italic";
			@media (max-width: 768px) {
				text-align: left;
				font-size: 1rem;
				padding: 0;
				margin: 0;
			}
		}
	}
	&__warning{
		@include mobile{
			padding: 1rem;
			text-align: center;
		}
	}
}

.menu__list-item,
.menu__list-description {
	flex: 1;
}

@keyframes resfadein {
	to {
		opacity: 1;
	}
}

// Applies the animation to both the list items and the image containers
.menu__list.is-inView,
.menu-item.is-inView {
	opacity: 1;
	transform: translateY(0px);
	transition: opacity 0.75s ease-out, transform 0.75s ease-out;
}

.menu__list,
.menu-item {
	opacity: 0;
	transform: translateY(20px);
	// Ensure this initial state is applied so that there's a transition from this state
}

.menu__image {
	width: 100%;
	object-fit: cover;
	border-radius: 20px 20px 20px 20px;
	height: 200px;
	display: block; // Makes the image block-level for proper spacing

	@media (min-width: 768px) {
		max-height: 220px;
		width: 100%;
		padding: 0;
		margin: 0px 4rem 0rem 0rem;
		border-radius: 20px 20px 20px 20px;
		margin-bottom: 32px;
	}
}
