@mixin tablet {
	@media (min-width: $tablet-breakpoint) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: $desktop-breakpoint) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $tablet-breakpoint) {
    @content;
	}
}

$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
